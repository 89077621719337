import React from 'react';
import './Contact.scss';

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const Contact = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      isValid: false,
      email: '',
      name: '',
      phone: '',
      text: '',
      privacyBox: false,
      consentBox: false,
      boxesChecked: false,
    };
  }

  checkValid = () => {
    const {
      name, email, consentBox, privacyBox,
    } = this.state;

    if (name !== '' && validateEmail(email) && consentBox && privacyBox) {
      // then submit
      this.toggleModal();
    } else {
      // don't submit
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }

  handleConsentChange = (e) => {
    this.setState({ consentBox: e.target.checked });
  }

  handlePrivacyChange = (e) => {
    this.setState({ privacyBox: e.target.checked });
  }
  //  handleNameChange: function(e) {
  //   this.setState({email: e.target.value});
  // }

  toggleModal = () => {
    const { active } = this.state;
    // toggle the active boolean in the state
    // if modal is closed, open it
    this.setState({ active: !active });
  }

  render() {
    const { active } = this.state;
    return (
      <div id="contact" className="contact">
        <div className="contact-header">
          <h4>Get in touch to learn how I can help accelerate your business growth</h4>
        </div>
        <button
          type="button"
          className=" button is-rounded is-fullwidth is-white is-outlined OTButton-secondary-isdark OTButton"
          onClick={() => this.toggleModal()}
          onKeyDown={() => this.toggleModal()}
        >
          Contact Us
        </button>
        <div className={`modal ${active ? 'is-active' : null}`}>
          <div
            className="modal-background"
            onClick={() => this.toggleModal()}
            onKeyDown={() => this.toggleModal()}
          />

          <div className="modal-card">
            <section className="modal-card-body">
              <button
                className="modal-close is-large"
                aria-label="close"
                onClick={() => this.toggleModal()}
                onKeyDown={() => this.toggleModal()}
              />

              <form name="contact-footer" method="POST" data-netlify="true" autoComplete="on" action="/contact_success">
                <input type="hidden" name="form-name" value="contact-footer" />
                <input className="input aeonik" type="text" placeholder="Name" name="name" required onChange={this.handleNameChange} />
                <input className="input aeonik" type="text" placeholder="Phone number" name="phone number" />
                <input className="input aeonik" type="email" placeholder="Email" name="footer-email" required onChange={this.handleEmailChange} />
                <textarea className="textarea aeonik" placeholder="What you want to build?" name="text" />
                <div className="modal-checkboxes">
                  <label className="checkbox" htmlFor="terms">
                    <input id="terms" type="checkbox" name="Agreed to terms" required onChange={this.handlePrivacyChange} />
                    By submitting this form, I agree to Ortom Privacy Policy.
                  </label>
                  <label className="checkbox" htmlFor="consent">
                    <input id="consent" type="checkbox" name="Agreed to consent" onChange={this.handleConsentChange} />
                    I agree to my email being stored and used to receive newsletters, offers,
                    and promotional materials including by email and phone to
                    the contact information I am submitting.
                    I consent Ortom processing my personal data for these purposes and
                    as described in
                    the Privacy Policy. I understand that I can withdraw my consent at any time.
                  </label>
                </div>
                <button
                  type="submit"
                  className="button button-trans-arrow is-outlined is-rounded is-fullwidth OTButton-secondary OTButton"
                  onClick={() => this.checkValid()}
                  onKeyDown={() => this.checkValid()}
                >
                  Submit
                </button>
              </form>
              <div className="modal-footer">
                <p>General Inquiries</p>
                <a href="mailto:tom@ortom.ai">
                  <p className="orange">tom@ortom.ai</p>
                </a>

              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
};

export default Contact;
