import React, { useState, useEffect } from 'react';
import './SignUp.scss';
import jsonp from 'jsonp';
import queryString from 'query-string';

function SignUp() {
  function getTime() {
    return new Date().toLocaleTimeString('en-US', { timeZone: 'Europe/London', hour: 'numeric', minute: '2-digit' });
  }

  const [stateEmail, setEmail] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [time, setTime] = useState(getTime().split(':'));

  function getTimeString(time) {
    const timeString = getTime();
    console.log(timeString);
    setTime(timeString.split(':'));
  }

  useEffect(() => {
    const interval = setInterval(() => getTimeString(time), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const subscribeToNewsLetter = (email, event) => {
    // prevent page reload
    event.preventDefault();

    const formData = {
      EMAIL: email,
    };

    jsonp(`https://ortom.us6.list-manage.com/subscribe/post-json?u=e0a03e76204b2ee9149fc7636&amp;id=7ecb50801c&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
      // console.log('err:', err);
      // console.log('data:', data);
      // overwrite email with success or error message

      if (data.msg.includes('is already subscribed to list Ortom.')) {
        setEmail(email + ' is already subscribed to list');
      } else {
        // catch all for all errors
        setEmail(data.msg);
      }
    });
  };

  return (
    <div className="signUp">
      <div className="signup-location">
        <div className="signup-location-time">
          <h4>
            {time[0]}
            <span className="timeSep">:</span>
            {time[1]}
          </h4>
        </div>
        <div className="signup-location-city"><p>Manchester, UK</p></div>
      </div>
      <div>
        <p>Sign up for a weekly 5 mins briefing in AI and data strategy. </p>
        <br />
        <form autoComplete="on" onSubmit={(e) => subscribeToNewsLetter(stateEmail,e)}>
          <div className="field has-addons has-addons-centered is-fullwidth white-underline">
            <p className="control" style={{ width: '100%' }}>
              <input className="input input-simple" type="email" name="email" placeholder="Your email" onChange={(event) => setEmail(event.target.value)} value={stateEmail} />
            </p>
            <p className="control">
              <button type="submit" className={isClicked ? 'button is-primary intput-arrow' : 'button is-white is-inverted input-arrow'}>
                →
              </button>
            </p>
          </div>
        </form>
      </div>
      <div className="small-gap" />
    </div>
  );
}

export default SignUp;
